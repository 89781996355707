import React, { useMemo } from "react";
import Plyr, { APITypes } from "plyr-react";
import "plyr-react/dist/plyr.css";
import getVideoId from "get-video-id";
import { Document } from "@contentful/rich-text-types";

export type PlyrVideoProps = {
  video?: string | null;
  caption?: Document;
};

const PlyrVideoComponent = ({ video }: PlyrVideoProps) => {
  const typeParam = "video" as const;

  // Memoize the video data
  const videoData = useMemo(
    () => (video ? getVideoId(video) : { id: null, service: null }),
    [video]
  );

  const providerParam = useMemo(() => {
    switch (videoData.service) {
      case "youtube":
        return "youtube" as const;
      case "vimeo":
        return "vimeo" as const;
      default:
        return undefined;
    }
  }, [videoData.service]);

  // Memoize the video source
  const source = useMemo(() => {
    if (!videoData.id || !providerParam) return null;

    return {
      type: typeParam,
      sources: [
        {
          src: videoData.id,
          provider: providerParam
        }
      ]
    };
  }, [videoData.id, providerParam]);

  // Static video options
  const videoOptions = useMemo(
    () => ({
      controls: ["play", "current-time", "progress", "volume", "fullscreen"],
      volume: 5
    }),
    []
  );

  // Return null if the source is invalid
  if (!source) {
    return null;
  }

  return (
    <div>
      <Plyr source={source} options={videoOptions} />
    </div>
  );
};

PlyrVideoComponent.displayName = "PlyrVideoComponent";

export const PlyrVideo = React.memo(PlyrVideoComponent);
